import React, { useState } from "react";

export const UpcomingGames = (props) => {
  // props = {
  //   day: props.day,
  //   time: props.time,
  //   team1: props.team1,
  //   team2: props.team2,
  // };
  const mediaQuery = window.matchMedia("(min-width: 768px)");
  if (mediaQuery.matches) {
    return (
      <div
        className="my-1 bg-gray-200 border-gray-200 border-2 rounded-xl flex flex-col justify-center items-center"
        style={{ width: 130 }}
      >
        <span className="text-xs text-center">
          <span className="font-semibold">
            {props.day} @{props.time}
          </span>{" "}
          <br /> {props.team1} VS {props.team2}
        </span>
      </div>
    );
  } else if (!mediaQuery.matches) {
    return (
      <div
        className="my-1 bg-gray-200 border-gray-200 border-2 rounded-xl flex flex-col justify-center items-center"
        style={{ width: 130 }}
      >
        omar
      </div>
    );
  }
};

export const WeekView = (props) => {
  // var week = props.week || 0;

  const {
    week,
    setWeek,
    yearAggregate,
    setYearAggregate,
    year,
    incomplete,
    totalWeeks,
  } = props;

  const backClick = () => {
    if (yearAggregate) {
      setYearAggregate(false);
      setWeek(totalWeeks);
    } else if (week > 1) {
      setWeek(week - 1);
    } else {
      setYearAggregate(true);
    }
  };

  const forwardClick = () => {
    if (yearAggregate) {
      setYearAggregate(false);
      setWeek(1);
    } else if (week < totalWeeks) {
      setWeek(week + 1);
    } else {
      setYearAggregate(true);
    }
  };

  return (
    <div className="w-full flex justify-center px-4">
      <div
        style={{ backgroundColor: "#003459", borderColor: "#003459" }}
        className="mt-2 w-full border-2 rounded-xl flex justify-between items-center"
      >
        <button
          aria-label="back"
          className="ci-button week-btn-left"
          onClick={backClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="35"
            viewBox="0 -960 960 960"
            width="48"
            fill="white"
          >
            <path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" />
          </svg>
        </button>
        {yearAggregate && (
          <h1 className="text-white text-md sm:text-lg md:text-xl">
            NFL Regular Season: {year}
            <br className="sm:hidden" />
            {incomplete && (
              <span>
                {" "}
                ( <span style={{ color: "red" }}> Incomplete</span> )
              </span>
            )}
          </h1>
        )}
        {!yearAggregate && (
          <h1 className="text-white text-md sm:text-lg md:text-xl">
            NFL WEEK {week}: {year}
            {incomplete && (
              <span>
                {" "}
                ( <span style={{ color: "red" }}> Incomplete</span> )
              </span>
            )}
          </h1>
        )}
        <button
          aria-label="forward"
          className="ci-button week-btn-right"
          onClick={forwardClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="35"
            viewBox="0 -960 960 960"
            width="48"
            fill="white"
          >
            <path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};
