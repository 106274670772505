import logo from "../../images/na_a.png";

export const buildConferenceView = (
  conferenceRankingData,
  setConferenceTeamView,
  statFilter
) => {
  const data = conferenceRankingData.map((item, i) => {
    let row = [
      <img
        width="50px"
        height="50px"
        alt="logo"
        src={item.logo ? item.logo : logo}
      ></img>,
      i + 1,
      <span
        className="conference-link"
        onClick={() => {
          setConferenceTeamView(item.id);
        }}
      >
        {item.conference}
      </span>,
      parseFloat(item.average).toLocaleString("en-US"),
      parseFloat(item.points).toLocaleString("en-US"),
      /*       item.teamCount,
      item.teamsContributing, */
    ];

    if (statFilter && statFilter !== "kicking") {
      row = row.concat([item.tds, item.yds]);
    }

    return row;
  });

  let res = {
    data,
    columns: [
      [
        " ",
        "Rank",
        "Conference",
        "Avg Pts Per Team",
        "Conference Points",
        /*         "Conference Team Count",
        "Teams Contributing", */
      ],
    ],
  };

  if (statFilter && statFilter !== "kicking") {
    let statHeader = statFilter[0].toUpperCase() + statFilter.substring(1);
    res.columns[0] = res.columns[0].concat([
      `${statHeader} TD's`,
      `${statHeader} YDS`,
    ]);
  }

  return res;
};

export const buildConferenceTeamView = (
  conferenceTeamRankingData,
  collegeData,
  setTeamView,
  statFilter
) => {
  let conferenceScore = 0;
  const data = conferenceTeamRankingData.map((item, i) => {
    conferenceScore += item.total;
    //collegeData[item.key]
    let row = [
      <img
        width="50px"
        height="50px"
        alt="logo"
        data-key={item.id}
        src={
          collegeData[item.id] && collegeData[item.id].logos
            ? collegeData[item.id].logos[0].href
            : logo
        }
      ></img>,
      i + 1,
      collegeData[item.id] ? (
        <span
          className="college-link"
          onClick={() => {
            setTeamView(item.id);
          }}
        >
          {collegeData[item.id].displayName}
        </span>
      ) : (
        "Not found " + JSON.stringify(collegeData[item.id])
      ),
      parseFloat(item.total.toFixed(2)).toLocaleString("en-US"),
      item.playerCount || 0,
    ];
    if (statFilter && statFilter !== "kicking") {
      row = row.concat([item.tds, item.yds]);
    }
    return row;
  });

  let res = {
    data,
    columns: [[" ", "Rank", "College", "Points", "No. Players Contributing"]],
    conferenceScore: conferenceScore.toFixed(2),
  };

  if (statFilter && statFilter !== "kicking") {
    let statHeader = statFilter[0].toUpperCase() + statFilter.substring(1);
    res.columns[0] = res.columns[0].concat([
      `${statHeader} TD's`,
      `${statHeader} YDS`,
    ]);
  }

  return res;
};

export const getConferenceView = ({
  conferenceTeamView,
  data,
  info,
  setConferenceTeamView,
  setTeamView,
  collegiateFantasyData,
  setConferenceViewDetail,
  statFilter,
}) => {
  data = data.filter((item) => item);
  if (!conferenceTeamView) {
    return buildConferenceView(data, setConferenceTeamView, statFilter);
  } else {
    const { conference, season, rank, high, low } = info;

    const result = buildConferenceTeamView(
      data,
      collegiateFantasyData.colleges,
      setTeamView,
      statFilter
    );

    setConferenceViewDetail({
      logo: (
        <img
          width="50px"
          height="50px"
          alt="logo"
          style={{ margin: "0 auto" }}
          src={conference.logo}
        />
      ),
      name: conference.shortName,
      score: result.conferenceScore,
      season,
      rank,
      rankHigh: high,
      rankLow: low,
    });

    return result;
  }
};
