export const buildColumnWidths = (viewDetail, gridWidth, statFilter) => {
  let columnWidths = [],
    gWidth;

  const doStatFilter = statFilter && statFilter !== "kicking";

  if (viewDetail) {
    let detailColumn1 = 200,
      detailColumn2 = 200;
    if (gridWidth <= 450) {
      detailColumn1 = 80;
      detailColumn2 = 50;
    } else if (gridWidth <= 590) {
      detailColumn1 = 80;
      detailColumn2 = 80;
    } else if (gridWidth <= 800) {
      detailColumn1 = 125;
      detailColumn2 = 125;
    } else if (gridWidth <= 1000) {
      detailColumn1 = 180;
      detailColumn2 = 180;
    }
    gWidth = gridWidth - 6 - (detailColumn1 + detailColumn2);
    columnWidths = [detailColumn1, detailColumn2, gWidth];
  } else {
    gWidth = (gridWidth - 6 - 150) / (doStatFilter ? 5 : 3);
    columnWidths = [70, 80, gWidth, gWidth, gWidth];
    if (doStatFilter) {
      columnWidths = columnWidths.concat([gWidth, gWidth]);
    }
  }

  return columnWidths;
};

export const buildRowHeights = (viewDetail, data, standardHeight) => {
  let rowHeights = [];

  if (viewDetail && data[0].length === 3) {
    rowHeights = data.map((item) => {
      return item[2].props.children.props.children.length * 140;
    });
  } else {
    rowHeights = new Array(data.length).fill(standardHeight);
  }
  return rowHeights;
};

/* TODO:
Handle switching between college and conference views in detail fiew (one option is just to hide the button when in a detail view)
*/
