import React, { useEffect } from "react";

import { getAnalytics, logEvent } from "firebase/analytics";

export const About = () => {
  const analytics = getAnalytics();

  useEffect(() => {
    logEvent(analytics, "About Page", { visit: true });
  }, []);

  return (
    <>
      <div className="container mx-auto p-4 bg-white mt-8 rounded-md">
        <h1 className="text-3xl font-bold mb-4">
          Introducing Collegiate NFL Impact: Unveiling the True Impact of
          College Teams and Conferences in the NFL
        </h1>
        <p className="my-5">
          Our mission is to bring clarity to the world of Collegiate NFL
          performance by measuring the true impact of each college team and
          conference on the professional football stage. We understand the
          passion that runs deep among college football fans, and we are here to
          show you how your favorite college measures up against others in the
          NFL, both on a weekly basis and over the entire season.
        </p>

        <p className="my-5">
          <span style={{ fontWeight: "bold" }}>Unveil the True Impact</span>: At
          Collegiate NFL Impact, we recognize the boxscore as the prevailing and
          widely adopted standard for gauging the performance and impact of
          individual players in the NFL. That's why we've meticulously designed
          a comprehensive system that assesses the performance of individual
          athletes who compete and are accounted for in the boxscore of NFL
          games every week and throughout the season. By directly correlating
          each player's performance with their college football team and
          conference, we provide a clear picture of the impact these
          institutions make on the NFL.
        </p>

        <p className="my-5">
          <span style={{ fontWeight: "bold" }}>
            Leveraging Fantasy Football Scoring
          </span>
          : To accurately measure player impact, we've harnessed the power of
          Fantasy Football scoring systems. These systems have been honed and
          refined over decades of iterations in the world of Fantasy Football.
          By utilizing these proven metrics, we provide you with an in-depth
          analysis of each contributor's impact, reflected in the boxscore.
        </p>

        <p className="my-5">
          <span style={{ fontWeight: "bold" }}>The Benefits for You</span>: Our
          website brings you a wealth of knowledge and exciting insights that
          will fuel your college football spirit. Here's what you can expect:
        </p>

        <p className="my-5 ml-10">
          <ol className="list-decimal mt-2">
            <li>
              <span style={{ fontWeight: "bold" }}>
                Weekly and Seasonal Comparisons
              </span>
              : Stay up-to-date with how your college team performs in the NFL
              week after week throughout the entire season. Uncover trends and
              see how your college's performance stacks up against others in
              real-time.
            </li>
            <li className="mt-1">
              <span style={{ fontWeight: "bold" }}>Conference Comparison</span>:
              Not only will you get to see how your college fares in the NFL,
              but you'll also discover how your college's conference performs as
              a whole. Witness the collective impact of conferences and gauge
              their strengths and weaknesses on the professional football stage.
            </li>
            <li className="mt-1">
              <span style={{ fontWeight: "bold" }}>Past Years Analysis</span>:
              Our database includes data from previous years, allowing you to
              trace the historical impact of your college and conference in the
              NFL. Compare the trends over time and witness the rise of your
              alma mater or favorite team through the years.
            </li>
          </ol>
        </p>

        <p className="my-5">
          We invite you to embrace the true essence of football performance at
          Collegiate NFL Impact. Experience the thrill of understanding how your
          college team and conference shape the world of professional football,
          and let the data speak for itself. Are you ready to unveil the real
          impact? Join us today!
        </p>

        <h2 className="text-2xl font-bold mt-4">
          Fantasy Point Scoring Details:
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 mb-4">
          <div>
            <h3 className="text-xl font-bold">Passing:</h3>
            <ul className="list-disc ml-6 mt-2">
              <li>1 points per 25 passing yards</li>
              <li>Passing TD 4 points</li>
              <li>Int -2 points</li>
            </ul>
          </div>

          <div>
            <h3 className="text-xl font-bold">Receiving:</h3>
            <ul className="list-disc ml-6 mt-2">
              <li>Points Per Reception: .5</li>
              <li>.1 For each yard</li>
              <li>6 For a TD</li>
            </ul>
          </div>

          <div>
            <h3 className="text-xl font-bold">Rushing:</h3>
            <ul className="list-disc ml-6 mt-2">
              <li>.1 For each yard</li>
              <li>6 For a TD</li>
            </ul>
          </div>

          <div>
            <h3 className="text-xl font-bold">Defense:</h3>
            <ul className="list-disc ml-6 mt-2">
              <li>Tackle (Solo): 1.5, (Assist): .75</li>
              <li>Tackle for loss: 1.25</li>
              <li>Sack: 1</li>
              <li>Quarterback hits: 1</li>
              <li>Passes Defended: 1.75</li>
              <li>Touchdowns: 6</li>
            </ul>
          </div>

          <div>
            <h3 className="text-xl font-bold">Kicking:</h3>
            <ul className="list-disc ml-6 mt-2">
              <li>Extra Points: 1 point</li>
              <li>Missed Extra Point: -2 points</li>
              <li>Missed Field Goal: -2 points</li>
              <li>Field Goal: 3 points</li>
              <li>Take Longest Field Goal</li>
              <ul className="list-disc ml-6 mt-2">
                <li>.1 bonus for every yard over 40</li>
                <li>Bonus increases to .15 for every yard over 55</li>
                <li>Bonus increases to .2 for every yard over 60</li>
              </ul>
            </ul>
          </div>

          <div>
            <h3 className="text-xl font-bold">Punting:</h3>
            <ul className="list-disc ml-6 mt-2">
              <li>0.2 points for each punt attempt</li>
              <li>1 point for an Average Yard Punt (AYP) of 35-39.99 yards</li>
              <li>3 points for an AYP of 40-44.99 yards</li>
              <li>4 points for an AYP of 45-49.99 yards</li>
              <li>6 points for an AYP of 50+ yards</li>
              <li>
                2 points for each punt that lands inside the opposing team’s
                20-yard line
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-xl font-bold">Kick Returns:</h3>
            <ul className="list-disc ml-6 mt-2">
              <li>6 pts for a TD</li>
              <li>1 points per 25 kick return yards</li>
            </ul>
          </div>

          <div>
            <h3 className="text-xl font-bold">Punt Returns:</h3>
            <ul className="list-disc ml-6 mt-2">
              <li>6 pts for a TD</li>
              <li>.1 pts per punt return yard</li>
            </ul>
          </div>

          <div>
            <h3 className="text-xl font-bold">Fumbles:</h3>
            <ul className="list-disc ml-6 mt-2">
              <li>Fumble not recovered by self -2 points</li>
              <li>Fumble recovered (not lost by self 3 points)</li>
            </ul>
          </div>

          <div>
            <h3 className="text-xl font-bold">Interceptions:</h3>
            <ul className="list-disc ml-6 mt-2">
              <li>3 pts for an Int</li>
              <li>.1 for interception yds</li>
              <li>6 pts for a interception td</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

/* 

TODO: Search, multiple tags would be nice, somehow keep ranking etc..

Later:
Routing?  Add more routes/views so that pages are more easily sharable.
Tweet button?  Make it easier to tweet your screen?
Filter statistics by stat categories, (offense, defense, kicking, passing, rushing, recieiving etc.. )

*/
