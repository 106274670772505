import React, { useState, useEffect } from "react";
import { VariableSizeGrid as Grid } from "react-window";
import { conferenceData } from "./conferenceData.js";
import { buildTeamDetailView } from "./util/team-view.js";
import { buildColumnWidths, buildRowHeights } from "./util/grid-util.js";

import { getConferenceView } from "./util/conference-view.js";
import { getTeamView } from "./util/team-view.js";

import AutoSizer from "react-virtualized-auto-sizer";
import worker from "workerize-loader!./scripts/data/core.js";
import { getAnalytics, logEvent } from "firebase/analytics";

const instanceWorker = worker(); // `new` is optional

export const Rankings = (props) => {
  const {
    data,
    columns,
    setTeamView,
    teamViewDetail,
    conferenceViewDetail,
    setConferenceTeamView,
    conferenceTeamView,
    filters,
    loading,
    statFilter,
  } = props;

  const [filteredData, setFilteredData] = useState([]);
  const [rowHeights, setRowHeights] = useState([]);
  const [columnWidths, setColumnWidths] = useState([]);
  const [gridWidth, setGridWidth] = useState(0);

  const mediaQuery = window.matchMedia("(min-width: 768px)");

  var style = {
    input: {
      "padding-bottom": "100px",
    },
    table: {
      border: "3px solid #93C5FE",
      "margin-left": "auto",
      "margin-right": "auto",
      // 'border-radius': '100px',
      // 'box-shadow': '5px 10px 4px #888888',
      // blur: '200px',
      filter:
        "drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1))",
    },
    th: {
      "background-color": "rgb(147 197 253)",
      color: "#000",
      border: "1px solid white",
      "border-bottom": "3px solid #93C5FE",
      "text-align": "left",
      "padding-left": "5px",
    },
    td: {
      "background-color": "white",
      "text-align": "left",
      border: "1px solid #93C5FE",
      "padding-left": "5px",
    },
  };

  if (mediaQuery.matches) {
    style = {
      table: {
        border: "3px solid #93C5FE",
        width: "83.333333%",
        "margin-left": "auto",
        "margin-right": "auto",
        filter:
          "drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1))",
      },
      th: {
        "background-color": "rgb(147 197 253)",
        color: "#000",
        border: "1px solid white",
        "border-bottom": "3px solid #93C5FE",
        "text-align": "left",
        "padding-left": "5px",
      },
      td: {
        "background-color": "white",
        "text-align": "left",
        border: "1px solid #93C5FE",
        "padding-left": "5px",
      },
    };
  }

  useEffect(() => {
    if (filters.length && !(conferenceTeamView || teamViewDetail)) {
      const fData = data.filter((item) => {
        return filters.find((filter) => {
          if (!item[2].props.children.toLowerCase) {
            return true;
          }
          return (
            item[2].props.children
              .toLowerCase()
              .indexOf(filter.toLowerCase()) >= 0
          );
        });
      });
      setFilteredData(fData);
    } else {
      setFilteredData(data);
    }
  }, [data, filters, conferenceTeamView, teamViewDetail]);

  const truncateText = (item, length = 12) => {
    if (gridWidth < 590 && typeof item === "string" && item.length > length) {
      return item.substring(0, length - 2) + "..";
    }
    return item;
  };

  const Cell = ({ columnIndex, rowIndex, style }) => (
    <div
      className="text-xs sm:text-sm md:text-base"
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        paddingLeft: "5px",
        borderLeft: ".5px solid #00A7E1",
        backgroundColor:
          rowIndex % 2 === 0 ? "rgb(245, 245, 245)" : "rgb(255 255 255)",
        overflowX: "scroll",
        overflowY: "hidden",
      }}
    >
      {filteredData &&
      filteredData[rowIndex] &&
      (filteredData[rowIndex][columnIndex] ||
        filteredData[rowIndex][columnIndex] === 0)
        ? filteredData[rowIndex][columnIndex]
        : "nothing"}
    </div>
  );

  const CellHeader = ({ columnIndex, rowIndex, style }) => (
    <div
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        paddingTop: "5px",
        paddingBottom: "5px",
      }}
    >
      <div
        className="text-xs sm:text-sm md:text-base"
        style={{
          borderLeft: "1px solid white",
          paddingLeft: "5px",
          marginLeft: "1.5px",
        }}
      >
        {columns && columns.length
          ? truncateText(columns[rowIndex][columnIndex])
          : "nothing"}{" "}
      </div>
      {/* Todo maybe somewhere else */}
    </div>
  );

  useEffect(() => {
    if (gridWidth > 0 && filteredData.length > 0) {
      //setRowHeight(teamViewDetail ? 600 : 60); //change to build row heights function
      setRowHeights(buildRowHeights(teamViewDetail, filteredData, 60));
      setColumnWidths(buildColumnWidths(teamViewDetail, gridWidth, statFilter));
    }
  }, [conferenceViewDetail, teamViewDetail, gridWidth, filteredData]);

  const footerHeight = 15;

  /*   const columnWidths = new Array(1000)
    .fill(true)
    .map(() => 75 + Math.round(Math.random() * 50));
  const rowHeights = new Array(1000)
    .fill(true)
    .map(() => 25 + Math.round(Math.random() * 50)); */

  return (
    <div style={{ height: "100%" }}>
      {(teamViewDetail || (conferenceTeamView && conferenceViewDetail)) && (
        <div className="mt-4 -mb-2">
          <div
            className="p-4"
            style={{
              borderRadius: "3px",
              textAlign: "center",
              backgroundColor: "rgb(33, 33, 33)",
              maxWidth: "400px",
              margin: "0 auto",
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 4px 10px, rgba(0, 0, 0, 0.2) 0px 15px 40px",
              border: "0.5px solid rgb(0 166 225 / 25%)",
            }}
          >
            {teamViewDetail && (
              <div className="text-white">
                <div>
                  <div
                    style={{ width: "50px", margin: "0 auto" }}
                    className="bg-white"
                  >
                    {teamViewDetail.image}
                  </div>
                  {teamViewDetail.college.displayName}
                  <br />
                  Total: {formatNumber(teamViewDetail.total)}
                  <br />
                  {teamViewDetail.season ? "Season" : ""} Rank:{" "}
                  {teamViewDetail.rank || "Unranked"}
                  <br />
                  {teamViewDetail.rankHigh && (
                    <div style={{ color: "#00A7E1" }} className="text-sm">
                      Rank High: {teamViewDetail.rankHigh.value}
                      <span className="text-xs">
                        {" ( Week "}
                        {teamViewDetail.rankHigh.week.split("_")[1]} )
                      </span>
                      <br />
                      Rank Low:{" "}
                      {teamViewDetail.rankLow.value === 9999
                        ? "Unranked"
                        : teamViewDetail.rankLow.value}
                      <span className="text-xs">
                        {" ( Week "}
                        {teamViewDetail.rankLow.week.split("_")[1]} )
                      </span>
                    </div>
                  )}
                </div>
                <button
                  className="ci-button"
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    fontSize: "12px",
                    marginTop: "3px",
                    backgroundColor: "rgb(0, 52, 89)",
                  }}
                  onClick={() => {
                    setTeamView("");
                  }}
                >
                  Back To {conferenceTeamView ? "Conference Teams" : ""}{" "}
                  Rankings View
                </button>
              </div>
            )}
            {!teamViewDetail && conferenceTeamView && conferenceViewDetail && (
              <div className="text-white">
                <div>
                  <div
                    style={{ width: "50px", margin: "0 auto" }}
                    className="bg-white"
                  >
                    {conferenceViewDetail.logo}
                  </div>
                  {conferenceViewDetail.name}
                  <br />
                  Total: {formatNumber(conferenceViewDetail.score)}
                  <br />
                  {conferenceViewDetail.season ? "Season" : ""} Rank:{" "}
                  {conferenceViewDetail.rank || "Unranked"}
                  <br />
                  {conferenceViewDetail.rankHigh && (
                    <div style={{ color: "#00A7E1" }} className="text-sm">
                      Rank High: {conferenceViewDetail.rankHigh.value}
                      <span className="text-xs">
                        {" ( Week "}
                        {conferenceViewDetail.rankHigh.week.split("_")[1]} )
                      </span>
                      <br />
                      Rank Low:{" "}
                      {conferenceViewDetail.rankLow.value === 9999
                        ? "Unranked"
                        : conferenceViewDetail.rankLow.value}
                      <span className="text-xs">
                        {" ( Week "}
                        {conferenceViewDetail.rankLow.week.split("_")[1]} )
                      </span>
                    </div>
                  )}
                </div>
                <button
                  className="ci-button"
                  style={{
                    backgroundColor: "rgb(0, 52, 89)",
                    padding: "5px",
                    borderRadius: "5px",
                    fontSize: "12px",
                    marginTop: "3px",
                  }}
                  onClick={() => {
                    setConferenceTeamView("");
                  }}
                >
                  Back To Conference View
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      <div style={{ height: "100%", maxWidth: "1400px", margin: "auto" }}>
        <br />
        <AutoSizer
          onResize={({ height, width }) => {
            setGridWidth(width);
          }}
        >
          {({ height, width }) => {
            // Use these actual sizes to calculate your percentage based sizes
            return (
              !loading && (
                <div
                  style={{
                    height,
                    width,
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                >
                  <Grid
                    className="ci-grid"
                    key={columnWidths.join("") + "1" + (rowHeights[0] || 60)}
                    style={{
                      margin: "0 auto",
                      borderLeft: "3px solid #00A7E1",
                      borderRight: "3px solid #00A7E1",
                      backgroundColor: "#00A7E1",
                      fontWeight: "bold",
                      color: "white",
                      borderTopRightRadius: "5px",
                      borderTopLeftRadius: "5px",
                      overflow: "hidden",
                    }}
                    columnCount={columnWidths.length}
                    columnWidth={(index) =>
                      /* (width - 6) / 5 */ columnWidths[index]
                    }
                    height={35}
                    rowCount={1}
                    rowHeight={(index) => 35 /* rowHeights[index] */}
                    width={width}
                  >
                    {CellHeader}
                  </Grid>
                  <Grid
                    className="ci-grid"
                    id="ci-grid-base"
                    key={columnWidths.join("") + "2" + (rowHeights[0] || 60)}
                    style={{
                      margin: "0 auto",
                      borderLeft: "3px solid #00A7E1",
                      borderRight: "3px solid #00A7E1",
                      borderBottom: "3px solid #00A7E1",
                      borderBottomLeftRadius: "5px",
                      borderBottomRightRadius: "5px",
                      backgroundColor: "white",
                      overflowX: "unset",
                    }}
                    columnCount={columnWidths.length}
                    columnWidth={(index /* (width - 6) / 5 */) =>
                      columnWidths[index]
                    }
                    height={
                      filteredData &&
                      filteredData.length &&
                      filteredData.length * (rowHeights[0] || 60) > height
                        ? height - footerHeight
                        : filteredData && filteredData.length
                        ? filteredData.length * (rowHeights[0] || 60)
                        : 0
                    }
                    rowCount={filteredData ? filteredData.length : 0}
                    rowHeight={(index) => rowHeights[index] || 60}
                    width={width}
                  >
                    {Cell}
                  </Grid>
                  {filteredData.length === 0 && (
                    <div style={{ width: "90vw", color: "orangered" }}>
                      <p>
                        {conferenceTeamView || teamViewDetail
                          ? "No Data On Record For This Week/Season."
                          : "No Data Available In This View, Change Views or Remove Filters."}
                      </p>
                    </div>
                  )}
                  <div style={{ height: `${footerHeight}px` }}></div>
                </div>
              )
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
};

export const GetData = (props) => {
  const {
    year,
    week,
    yearAggregate,
    conferenceView,
    filters,
    conferenceTeamView,
    setConferenceTeamView,
    teamViewDetail,
    setTeamViewDetail,
    setIncomplete,
    setTotalWeeks,
    statFilter,
  } = props;
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [teamView, setTeamView] = useState("");
  const [conferenceViewDetail, setConferenceViewDetail] = useState(null);

  const [collegiateFantasyData, setCollegiateFantasyData] = useState(null);
  const [collegiateConferenceData, setCollegiateConferenceData] =
    useState(null);

  const [loading, setLoading] = useState(false);

  const analytics = getAnalytics();

  useEffect(() => {
    if (conferenceView) {
      setCollegiateConferenceData(conferenceData);
    } else {
      setCollegiateConferenceData(null);
    }
  }, [conferenceView]);

  useEffect(() => {
    getCFData(year, year === 2024).then((result) => {
      setCollegiateFantasyData(result);
      setTotalWeeks(Object.keys(result.data).length);
    });
  }, [year, statFilter]);

  const getCFData = (year, current = false) => {
    return fetch(
      current
        ? `https://firebasestorage.googleapis.com/v0/b/collegiate-nfl-impact.appspot.com/o/season_current%2F${year}.json?alt=media`
        : `https://firebasestorage.googleapis.com/v0/b/collegiate-nfl-impact.appspot.com/o/season_complete%2F${year}.json?alt=media`
    )
      .then((response) => response.json())
      .then((data) => {
        return instanceWorker.handleStatFilter({
          data,
          statFilter,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    logEvent(analytics, "Core Action", {
      action: true,
    });
    setIncomplete(false);

    if (collegiateFantasyData) {
      if (yearAggregate) {
        setIncomplete(collegiateFantasyData.incomplete || false);
      } else {
        setIncomplete(
          collegiateFantasyData.data[year + "_" + week].incomplete || false
        );
      }
    }
    if (!conferenceView) {
      //Something to fix something. (more elgant pattern?  Look into)
      setConferenceTeamView("");
    }
    if (collegiateConferenceData && conferenceView && !teamView) {
      setTeamViewDetail(null); //Something to fix something.
      setLoading(true);
      instanceWorker
        .handleConferenceView({
          yearAggregate,
          collegiateFantasyData,
          year,
          week,
          teamView,
          conferenceTeamView,
          collegiateConferenceData,
        })
        .then((result) => {
          const { data, info } = result;
          result = getConferenceView({
            conferenceTeamView,
            data,
            info,
            setConferenceTeamView,
            setTeamView,
            collegiateFantasyData,
            setConferenceViewDetail,
            statFilter,
          });
          const { columns } = result;
          setColumns(columns);
          setData(result.data);
          if (document.getElementById("ci-grid-base")) {
            document.getElementById("ci-grid-base").scrollTop = 0;
          }
          setLoading(false);
        });
    } else if (collegiateFantasyData && teamView) {
      setLoading(true);
      instanceWorker
        .handleTeamDetailView({
          yearAggregate,
          collegiateFantasyData,
          year,
          week,
          teamView,
          conferenceTeamView,
          collegiateConferenceData,
        })
        .then((teamDetailViewData) => {
          const result = buildTeamDetailView(
            teamDetailViewData.data,
            teamDetailViewData.info,
            setTeamViewDetail
          );
          const { data, columns } = result;
          setColumns(columns);
          setData(data);
          if (document.getElementById("ci-grid-base")) {
            document.getElementById("ci-grid-base").scrollTop = 0;
          }
          setLoading(false);
        });
    } else if (collegiateFantasyData) {
      setTeamViewDetail(null);
      setLoading(true);
      instanceWorker
        .handleRankingData({
          yearAggregate,
          collegiateFantasyData,
          year,
          week,
          teamView,
          conferenceTeamView,
          collegiateConferenceData,
        })
        .then((rankingData) => {
          let result = getTeamView(rankingData, setTeamView, statFilter);
          const { data, columns } = result;
          setColumns(columns);
          setData(data);
          if (document.getElementById("ci-grid-base")) {
            document.getElementById("ci-grid-base").scrollTop = 0;
          }
          setLoading(false);
        });
    }
  }, [
    week,
    teamView,
    yearAggregate,
    collegiateFantasyData,
    collegiateConferenceData,
    conferenceView,
    conferenceTeamView,
  ]);

  return (
    collegiateFantasyData && (
      // sortedArray
      <Rankings
        data={data}
        columns={columns}
        teamViewDetail={teamViewDetail}
        conferenceViewDetail={conferenceViewDetail}
        setTeamView={setTeamView}
        conferenceTeamView={conferenceTeamView}
        setConferenceTeamView={setConferenceTeamView}
        filters={filters}
        loading={loading}
        statFilter={statFilter}
      />
    )
  );
};

function formatNumber(number) {
  return number ? parseFloat(number).toLocaleString() : "0";
}

/* TODO, find a more performant grid */
//https://github.com/bvaughn/react-window
/* Good progress, todo: Variable size grid */
