import React, { useState } from "react";

const FilterTable = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const { filters, setFilters, setStatFilter } = props;

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchValue.trim() !== "") {
      setFilters([...filters, searchValue.trim()]);
      setSearchValue("");
    }
  };

  const handleRemoveFilter = (filter) => {
    setFilters(filters.filter((f) => f !== filter));
  };

  return (
    <div className="container mx-auto lg:my-4 lg:px-4 pt-4">
      <form className="m-auto w-24 lg:w-40" onSubmit={handleSearchSubmit}>
        <select
          style={{ backgroundColor: "#003459", outline: "none" }}
          onChange={(ev) => {
            setStatFilter(ev.target.value);
          }}
          className="block w-full p-2 pr-3 pl-3 text-white ci-button text-sm sm:text-md md:text-lg mb-1"
        >
          <option value="">All Stats</option>
          <option value="passing">Passing</option>
          <option value="receiving">Receiving</option>
          <option value="rushing">Rushing</option>
          <option value="kicking">Kicking & Punting</option>
          <option value="defense">Defense</option>
        </select>
        <input
          type="text"
          value={searchValue}
          onChange={handleSearchChange}
          className="text-sm lg:text-md border rounded px-3 py-2 filterInput w-24 lg:w-40 mt-4 lg:mt-0"
          style={{ backgroundColor: "#003459", borderColor: "#00A7E1" }}
          placeholder="Filter By Name"
        />
      </form>
      <div>
        {filters.map((filter, index) => (
          <div
            key={index}
            className="inline-block bg-blue-500 text-white px-2 py-1 rounded mr-2 mb-2 mt-2"
          >
            {filter}
            <button
              onClick={() => handleRemoveFilter(filter)}
              className="ml-2 focus:outline-none"
            >
              <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M14.293 5.293a1 1 0 011.414 1.414L11.414 12l4.293 4.293a1 1 0 11-1.414 1.414L10 13.414l-4.293 4.293a1 1 0 11-1.414-1.414L8.586 12 4.293 7.707a1 1 0 111.414-1.414L10 10.586l4.293-4.293z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        ))}
      </div>
      {/* Your HTML table with records will be here */}
    </div>
  );
};

export default FilterTable;
