import React, { useState } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import ReactDOM from "react-dom";
import "./style.css";
import { NFL } from "./images/icons";
import { UpcomingGames, WeekView } from "./files/utilities";
import { GetData } from "./files/team-grid";
import { About } from "./files/about";
import FilterTable from "./files/scripts/view/filterTable";
import Logo from "./images/logo.svg";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCQFV7Mgj8B7qHz9aQR7CIul-INKv4dgdo",
  authDomain: "collegiate-nfl-impact.firebaseapp.com",
  projectId: "collegiate-nfl-impact",
  storageBucket: "collegiate-nfl-impact.appspot.com",
  messagingSenderId: "514358742000",
  appId: "1:514358742000:web:795548bb5a0f0b6fc4ab35",
  measurementId: "G-R3L9CJ0MT8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const [year, setYear] = useState(2024);
  const [week, setWeek] = useState(1);
  const [yearAggregate, setYearAggregate] = useState(true);
  const [conferenceView, setConferenceView] = useState(false);
  const [filters, setFilters] = useState([]);
  const [statFilter, setStatFilter] = useState("");
  const [conferenceTeamView, setConferenceTeamView] = useState("");
  const [teamViewDetail, setTeamViewDetail] = useState(null);
  const [incomplete, setIncomplete] = useState(false);
  const [totalWeeks, setTotalWeeks] = useState(1);

  return (
    <Router>
      <div
        className="bg-gray-50"
        style={{ height: "100%", display: "flex", flexFlow: "column" }}
      >
        <div style={{ flex: "0 1 auto" }}>
          <div
            className="flex items-center justify-start pt-3 pr-4 -mb-2 p-1"
            style={{ backgroundColor: "#212121" }}
          >
            <nav className="ml-auto pr-6">
              <ul className="flex space-x-4">
                <li style={{ zIndex: "999" }}>
                  <Link
                    to="/"
                    className="font-bold text-white transition duration-300 ci-text-button"
                  >
                    Home
                  </Link>
                </li>
                <li style={{ zIndex: "999" }}>
                  <Link
                    to="/about"
                    className="font-bold text-white transition duration-300 ci-text-button"
                  >
                    About
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div
          style={{
            paddingLeft: "25px",
            paddingRight: "25px",
            flex: "1 1 auto",
          }}
        >
          <Routes>
            <Route
              exact
              path="/"
              element={
                <>
                  <div
                    style={{
                      margin: "0 auto",
                      marginBottom: "8px",
                      marginTop: "4px",
                      paddingBottom: "22px",
                      borderRadius: "3px",
                      maxWidth: "1400px",
                      backgroundColor: "#212121",
                      boxShadow:
                        "rgba(0, 0, 0, 0.05) 0px 4px 10px, rgba(0, 0, 0, 0.2) 0px 15px 40px",
                    }}
                    className="flex gap-2"
                  >
                    {/* First Column */}
                    <div
                      className="flex-0 hidden md:block"
                      style={{ width: "230px", height: "200px" }}
                    >
                      <img
                        width="400"
                        height="400"
                        style={{
                          maxWidth: "inherit",
                          margin: "-115px 0px 0px -85px",
                        }}
                        alt="Collegiate NFL Impact Logo"
                        src={Logo}
                      />
                    </div>

                    {/* Second Column */}
                    <div className="flex-1 flex flex-col">
                      {/* First Row of Second Column */}
                      <div className="h-1/2 flex-grow">
                        <div className="grid grid-cols-3 grid-rows-1 gap-0 h-full">
                          <div className="flex items-center pt-6 col-span-3 md:col-span-1">
                            {" "}
                            <button
                              style={{ backgroundColor: "#003459" }}
                              className="text-xs md:text-sm lg:text-md text-white font-bold py-2 px-4 rounded ci-button m-auto"
                              onClick={() => {
                                setConferenceView(!conferenceView);
                              }}
                            >
                              Change to{" "}
                              {conferenceView ? "College Team" : "Conference"}{" "}
                              View
                            </button>
                          </div>
                          <div className="flex items-center mb-0 col-span-1 sm:block hidden md:hidden">
                            <img
                              width="200"
                              height="200"
                              style={{
                                maxWidth: "inherit",
                                margin: "-90px 0px -73px 0px",
                              }}
                              alt="Collegiate NFL Impact Logo"
                              src={Logo}
                            />
                          </div>
                          <div className="flex items-center mb-0 col-span-1 sm:hidden">
                            <img
                              width="125"
                              height="125"
                              style={{
                                maxWidth: "inherit",
                                margin: "-60px -5px -74px",
                              }}
                              alt="Collegiate NFL Impact Logo"
                              src={Logo}
                            />
                          </div>
                          <div className="flex items-center col-span-1">
                            {" "}
                            <div className="m-auto flex">
                              <div className="mt-4 md:mt-3 lg:mt-3">
                                <label className="text-white mb-1 text-sm sm:text-md md:text-lg">
                                  Season
                                </label>
                                <select
                                  style={{ backgroundColor: "#003459" }}
                                  onChange={(ev) => {
                                    setYear(parseInt(ev.target.value));
                                  }}
                                  className="block w-full p-2 pr-3 pl-3 text-white ci-button text-sm sm:text-md md:text-lg"
                                >
                                  <option value="2024">2024</option>
                                  <option value="2023">2023</option>
                                  <option value="2022">2022</option>
                                  <option value="2021">2021</option>
                                  <option value="2020">2020</option>
                                  <option value="2019">2019</option>
                                  <option value="2018">2018</option>
                                  <option value="2017">2017</option>
                                  <option value="2016">2016</option>
                                  <option value="2015">2015</option>
                                  <option value="2014">2014</option>
                                  <option value="2013">2013</option>
                                  <option value="2012">2012</option>
                                  <option value="2011">2011</option>
                                  <option value="2010">2010</option>
                                </select>
                              </div>
                              <div className="mt-10 sm:hidden">
                                <NFL size="sm" />
                              </div>
                              <div className="mt-6 hidden sm:block">
                                <NFL size="m" />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center pt-2 col-span-1">
                            {" "}
                            <FilterTable
                              filters={filters}
                              setFilters={setFilters}
                              setStatFilter={setStatFilter}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Second Row of Second Column */}
                      <div className="h-1/2 flex-grow">
                        <div className="flex items-center h-full">
                          {" "}
                          <WeekView
                            week={week}
                            setWeek={setWeek}
                            year={year}
                            setYearAggregate={setYearAggregate}
                            yearAggregate={yearAggregate}
                            incomplete={incomplete}
                            totalWeeks={totalWeeks}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* TODO: Change theme color depending on view */}
                  {/* <UpcomingGames
                      day="Thursday"
                      time="5pm"
                      team1="Seahawks"
                      team2="Giants"
                    /> */}
                  {/* <UpcomingGames
                day="Thursday"
                time="5pm"
                team1="Seahawks"
                team2="Giants"
                />
                <UpcomingGames
                  day="Thursday"
                  time="5pm"
                  team1="Seahawks"
                  team2="Giants"
                />
                <UpcomingGames
                  day="Thursday"
                  time="5pm"
                  team1="Seahawks"
                  team2="Giants"
                />
                <UpcomingGames
                  day="Thursday"
                  time="5pm"
                  team1="Seahawks"
                  team2="Giants"
              /> */}
                  <GetData
                    week={week}
                    year={year}
                    yearAggregate={yearAggregate}
                    conferenceView={conferenceView}
                    filters={filters}
                    conferenceTeamView={conferenceTeamView}
                    setConferenceTeamView={setConferenceTeamView}
                    teamViewDetail={teamViewDetail}
                    setTeamViewDetail={setTeamViewDetail}
                    setIncomplete={setIncomplete}
                    setTotalWeeks={setTotalWeeks}
                    statFilter={statFilter}
                  />
                </>
              }
            />
            <Route path="/about" element={<About />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
