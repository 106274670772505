import noLogo from "../../images/na_a.png";

export const buildTeamDetailView = (combinedData, info, setTeamViewDetail) => {
  setTeamViewDetail({
    college: info.college,
    image: (
      <img
        width="50px"
        height="50px"
        alt="logo"
        style={{ margin: "0 auto" }}
        src={info.logo || noLogo}
      ></img>
    ),
    id: info.id,
    rank: info.rank,
    rankHigh: info.rankHigh,
    rankLow: info.rankLow,
    season: info.season,
    total: info.total,
  });

  const data = Object.keys(combinedData)
    .map((guid) => {
      const player = combinedData[guid];
      return [
        player.name,
        player.points.toFixed(2),
        <div className="p-2 w-full text-xs md:text-sm lg:text-base">
          <table className="table-fixed">
            {player.categories
              .sort((a, b) => b.points - a.points)
              .map((category, i) => (
                <>
                  <thead>
                    <tr>
                      <th className="text-left py-2"></th>
                      {Object.keys(category.stats).map((statKey, i) => {
                        return (
                          <th
                            className={
                              i === 0 ? "text-left py-2 pl-2" : "text-left py-2"
                            }
                          >
                            {statKey}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      style={{ backgroundColor: "" }}
                      className={
                        i % 2 === 0
                          ? "border-t border-blue-300 pb-5 bg-blue-100"
                          : "border-t border-blue-300 pb-5 bg-blue-100"
                      }
                    >
                      <td className="px-2">
                        {capitalizeFirstLetter(category.name)}
                      </td>
                      {Object.keys(category.stats).map((statKey, i) => {
                        return (
                          <td
                            className={
                              i === 0
                                ? "border-l border-blue-300 pl-2 py-2"
                                : ""
                            }
                          >
                            {category.stats[statKey]}
                          </td>
                        );
                      })}
                    </tr>
                    <tr style={{ color: "rgb(0, 167, 225)" }}>
                      <td className="w-1/6 text-left py-2 text-sm border-t border-blue-300">
                        Total
                      </td>
                      <td className="w-1/6 text-left py-2 text-sm border-t border-blue-300">
                        {category.points.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </>
              ))}
          </table>
        </div>,
      ];
    })
    .sort((a, b) => parseFloat(b[1]) - parseFloat(a[1]));

  return {
    data,
    columns: [["Player", "Points", "Details"]],
  };
};

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getTeamView = (data, setTeamView, statFilter) => {
  data = data.map((item) => {
    let row = [
      <img
        width="50px"
        height="50px"
        alt="logo"
        data-key={item.school}
        src={item.logo || noLogo}
      ></img>,
      item.rank,
      item.college ? (
        <span
          className="college-link"
          onClick={() => {
            setTeamView(item.school);
          }}
        >
          {item.college.displayName}
        </span>
      ) : (
        "Not found " + JSON.stringify(item.college)
      ),
      parseFloat(item.total.toFixed(2)).toLocaleString("en-US"),
      item.numPlayers,
    ];
    if (statFilter && statFilter !== "kicking") {
      row = row.concat([item.td, item.yds]);
    }
    return row;
  });

  let res = {
    data,
    columns: [[" ", "Rank", "College", "Points", "No. Players Contributing"]],
  };

  if (statFilter && statFilter !== "kicking") {
    let statHeader = statFilter[0].toUpperCase() + statFilter.substring(1);
    res.columns[0] = res.columns[0].concat([
      `${statHeader} TD's`,
      `${statHeader} YDS`,
    ]);
  }
  return res;
};
